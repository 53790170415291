import React from 'react'
import { Section, InputField } from './MerchantForm'

export default function LoginDetails() {
  return (
    <Section title="Login Details">
      <InputField
        name="email"
        type="email"
        label="What's your email? (format: name@example.com)"
        placeholder="charlie@pixiemagic.nz"
      />
      <InputField
        name="password"
        type="password"
        label="What's a good password for you here?"
      />
      <InputField
        name="passwordConfirm"
        type="password"
        label="Please confirm your password."
      />
    </Section>
  )
}
