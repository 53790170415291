import React from 'react'
import { Text } from 'rebass/styled-components'
import { Section, InputField } from './MerchantForm'

export default function BusinessDetails() {
  return (
    <Section title="Business Details">
      <Text p={3} fontFamily="body">
        This verification must be completed by someone authorised to sign on
        behalf of the organisation.
      </Text>
      <InputField
        name="businessNzbn"
        label="What's your New Zealand Business Number (NZBN) (format: 1234567890123)?"
        placeholder="345643563654"
      />
      <InputField
        name="businessName"
        label="What's your legal business name?"
        placeholder="Pixie Limited"
      />
      <InputField
        name="businessTradingName"
        label="What other name does your business trade as, if any?"
        placeholder="Pixie Magic"
      />
      <InputField
        as="textarea"
        name="businessAddress"
        label="What's your business address?"
        placeholder="123 Seseme St"
      />
      <InputField
        name="businessWebsite"
        label="What's your business website? (or link to social media page) (format: http://example.com)"
        placeholder="https://pixiemagic.nz"
      />
      <InputField
        as="textarea"
        name="businessDescription"
        label="How would you describe what you are selling?"
        placeholder="A splash of magic pixie dust"
      />
      {/*
      <InputField
        name="businessStatement"
        label="How do you want to show up on your customer's Choice transaction history?"
        placeholder="Pixie"
      />
      */}
    </Section>
  )
}
