import React, { useState, useEffect } from 'react'
import { Box, Flex, Text, Button } from 'rebass/styled-components'
import ky from 'ky'
import * as yup from 'yup'
import { useCheckboxState, Checkbox } from 'reakit/Checkbox'
import serializeFormData from '@octetstream/object-to-form-data'
import {
  assign,
  camelCase,
  fromPairs,
  isArray,
  toPairs,
  mapKeys,
  omit,
  snakeCase,
} from 'lodash'
import { Container, HeaderTitle, Masthead, SectionBox } from '../../base'
import {
  Form,
  Section,
  InputField,
  CaptchaField,
  SubmitButton,
} from './MerchantForm'
import LoginDetails from './LoginDetails'
import DirectorDetails from './DirectorDetails'
import BankDetails from './BankDetails'
import BusinessDetails from './BusinessDetails'

const PASSWORD_MIN_LENGTH = 4
const FILE_MAX_SIZE_IN_MB = 10
const FILE_ACCEPTABLE_FORMATS = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'application/pdf',
]

const testFileMaxSize = {
  name: 'testFileMaxSize',
  test: function isFileOverMaxSize(value) {
    if (value == null) return true
    return value.size <= FILE_MAX_SIZE_IN_MB * 1e6
  },
  message: `File is too big, must be less than ${FILE_MAX_SIZE_IN_MB} MB`,
}

const testFileFormat = {
  name: 'testFileFormat',
  test: function isFileAcceptableFormat(value) {
    if (value == null) return true
    return FILE_ACCEPTABLE_FORMATS.includes(value.type)
  },
  message: `File is wrong format, must be a .jpg, .png, .gif, or .pdf file.`,
}

const merchantFormSchema = yup.object({
  email: yup
    .string()
    .required('We need your email!')
    .email("Your email doesn't look right: wrong format"),
  password: yup
    .string()
    .required('We need your password!')
    .min(PASSWORD_MIN_LENGTH, "Your password isn't long enough."),
  passwordConfirm: yup
    .string()
    .required('We need you to confirm your password!')
    .oneOf([yup.ref('password'), null], 'Passwords do not match.'),
  directorLegalName: yup.string().required('We need your legal name!'),
  directorNickName: yup
    .string()
    .nullable()
    .notRequired(),
  directorAddress: yup.string().required('We need your address!'), // TODO matches()
  directorDateOfBirth: yup
    .string()
    .required('We need your date of birth!')
    .matches(
      /\d{4}-\d{2}-\d{2}/,
      "Your date of birth doesn't look right: wrong format",
    ),
  directorPhotoIdentification: yup
    .mixed()
    .required('We need your photo identification!')
    .test(testFileMaxSize)
    .test(testFileFormat),
  directorProofOfAddress: yup
    .mixed()
    .required('We need your proof of address!')
    .test(testFileMaxSize)
    .test(testFileFormat),
  businessNzbn: yup
    .string()
    .required('We need your NZBN!')
    .matches(/\d{13}/, "Your NZBN doesn't look right: wrong format"),
  businessName: yup.string().required('We need your business name!'),
  businessTradingName: yup
    .string()
    .nullable()
    .notRequired(),
  businessAddress: yup.string().required('We need your address!'), // TODO matches()
  businessWebsite: yup
    .string()
    .nullable()
    .notRequired()
    .url("This bank website URL doesn't look right: wrong format"),
  businessDescription: yup
    .string()
    .required('We need your business description!'),
  bankNumber: yup
    .string()
    .required('We need your business bank account number!')
    .matches(
      /\d{2}-\d{4}-\d{7}-\d{2,3}/,
      "This bank account number doesn't look right: wrong format",
    ),
  bankNumberConfirm: yup
    .string()
    .required('We need you to confirm your business bank account number!')
    .oneOf(
      [yup.ref('bankNumber'), null],
      'Business bank account numbers do not match.',
    ),
  /*
  statementName: yup
    .string()
    .nullable()
    .notRequired(),
  */
  captcha: yup
    .string()
    .nullable()
    .required(),
})

export type MerchantSignupValues = yup.InferType<typeof merchantFormSchema>

const INITIAL_VALUES = {
  email: '',
  password: '',
  passwordConfirm: '',
  directorLegalName: '',
  directorNickName: null,
  directorAddress: '',
  directorDateOfBirth: '',
  directorPhotoIdentification: null,
  directorProofOfAddress: null,
  businessNzbn: '',
  businessName: '',
  businessTradingName: null,
  businessAddress: '',
  businessWebsite: '',
  businessDescription: '',
  bankNumber: '',
  bankNumberConfirm: '',
  //  statementName: '',
  captcha: null,
}

// use for debugging
/*
const INITIAL_VALUES = {
  email: 'a@b.c',
  password: 'password',
  passwordConfirm: 'password',
  directorLegalName: 'a b',
  directorNickName: '',
  directorAddress: '123 a st',
  directorDateOfBirth: '1980-04-23',
  directorPhotoIdentification: null,
  directorProofOfAddress: null,
  businessNzbn: '1231231231231',
  businessName: 'a b',
  businessTradingName: 'a b c',
  businessAddress: '123 b st',
  businessWebsite: 'http://a.b',
  businessDescription: 'a b c',
  bankNumber: '12-1234-1234567-123',
  bankNumberConfirm: '12-1234-1234567-123',
  // statementName: 'a b c',
  captcha: null,
}
*/

interface BadRequestPayload {
  errors: object
}

interface MerchantSignupProps {
  isSubmitted: boolean
  navigateToSubmitted: () => void
}

export default function MerchantSignUp(
  props: MerchantSignupProps,
): React.ReactElement {
  const { isSubmitted, navigateToSubmitted } = props

  const onSubmit = React.useCallback(async (values, setServerErrors): Promise<
    void
  > => {
    const snakeValues = mapKeys(values, (v, k) => snakeCase(k))
    const { captcha } = snakeValues
    const merchant = omit(snakeValues, [
      'password_confirm',
      'bank_number_confirm',
      'captcha',
    ])
    const formValue = { merchant, captcha }
    const formData = serializeFormData(formValue)

    const response = await ky('merchant/signup', {
      prefixUrl: process.env.GATSBY_BACKEND_URL,
      headers: {
        accept: 'application/json',
      },
      method: 'post',
      body: formData,
      throwHttpErrors: false,
    })

    if (response.ok) {
      return navigateToSubmitted()
    } else if (response.status === 400) {
      const payload: BadRequestPayload = await response.json()
      const serverErrors = fromPairs(
        toPairs(payload.errors).map(([key, value]) => {
          const normalKey = camelCase(key)
          const normalValue = isArray(value) ? value[0] : value
          return [normalKey, normalValue]
        }),
      )
      return setServerErrors(serverErrors)
    }
  }, [])

  return (
    <>
      <Masthead py={4}>
        <SectionBox bg="primary.maim">
          <HeaderTitle color="primary.text">Merchant Sign Up</HeaderTitle>
        </SectionBox>
      </Masthead>

      {isSubmitted ? (
        <>
          <Text p={4}>
            Haere mai, welcome to Choice. Choice is New Zealand's homegrown
            payments system that is here to help kiwi businesses save money and
            redirect money to charity. Learn more about the Choice kaupapa and
            mission here. Before you can start processing payments or
            integrating Choice payments with your online store or point of sale
            terminal, we need a few details. We are required by New Zealand law
            to ask you to submit some information to verify that you're really
            you, and that you are authorised to set up a new payments method for
            your business. After you fill out the details below, we'll verify
            your account and get in touch with you as soon as we can. Then
            you're good to go!
          </Text>
        </>
      ) : (
        <MerchantSignUpForm onSubmit={onSubmit} />
      )}
    </>
  )
}

interface MerchantSignUpFormProps {
  onSubmit: (values) => void
}

function MerchantSignUpForm(
  props: MerchantSignUpFormProps,
): React.ReactElement {
  const { onSubmit } = props

  const [isConfirmed, setIsConfirmed] = useState(false)

  return (
    <SectionBox bg="primary.text">
      <Form<MerchantSignupValues>
        initialValues={INITIAL_VALUES}
        schema={merchantFormSchema}
        onSubmit={onSubmit}
      >
        <Introduction />
        <LoginDetails />
        <DirectorDetails />
        <BusinessDetails />
        <BankDetails />
        <Confirmation setIsConfirmed={setIsConfirmed} />
        <SubmitButton disabled={!isConfirmed} />
      </Form>
    </SectionBox>
  )
}

function Introduction() {
  return (
    <>
      <Text px={0} pt={4} pb={3}>
        Haere mai, welcome to Choice. Choice is New Zealand's homegrown payments
        system that is here to help kiwi businesses save money and redirect
        money to charity. Learn more about the Choice kaupapa and mission here.
        Before you can start processing payments or integrating Choice payments
        with your online store or point of sale terminal, we need a few details.
        We are required by New Zealand law to ask you to submit some information
        to verify that you're really you, and that you are authorised to set up
        a new payments method for your business. After you fill out the details
        below, we'll verify your account and get in touch with you as soon as we
        can. Then you're good to go!
      </Text>
      <Box
        as="a"
        href="/welcome-aboard"
        target="_blank"
        color="primary.main"
        px={4}
        py={3}
        css={{ display: 'block' }}
      >
        Learn more about our obligations and how we store your information
      </Box>
    </>
  )
}

interface ConfirmationProps {
  setIsConfirmed: (boolean) => void
}

function Confirmation(props: ConfirmationProps) {
  const { setIsConfirmed } = props
  const checkbox = useCheckboxState({ state: false })

  useEffect(() => setIsConfirmed(checkbox.state), [checkbox])

  return (
    <Section title="Confirmation">
      <Flex flexDirection="row" alignItems="center" px={3} py={3}>
        <Checkbox
          {...checkbox}
          color="primary.main"
          minWidth="unset"
          css={{ transform: 'scale(1.5)' }}
        />
        <Text px={3} fontFamily="body">
          I agree that the information I have provided above is complete and
          correct, and understand that by signing below I am agreeing to the
          terms of the
          <Text as="a" href="/merchant-terms" target="_blank" color="dark">
            &nbsp;Choice Merchant Services Agreement.
          </Text>
        </Text>
      </Flex>
      <CaptchaField
        name="captcha"
        sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
      />
    </Section>
  )
}
