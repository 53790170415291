import React, { useEffect, useState } from 'react'
import { assign, identity } from 'lodash'
import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  Text,
  TextProps,
} from 'rebass/styled-components'
import { useDropzone } from 'react-dropzone'
import {
  unstable_FormLabel as FormLabel,
  unstable_useFormInput as useFormInput,
  unstable_FormMessage as FormMessage,
} from 'reakit/Form'
import isFileAccepted from 'attr-accept'

import { FormContext } from './MerchantForm'

export default function FileInputField(props) {
  const { name, label, multiple = false } = props

  const form = React.useContext(FormContext)

  const [files, setFiles] = useState([])
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    multiple,
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      )
    },
  })

  const thumbs = files.map(file => (
    <Thumb key={file.name}>
      <ThumbInner>
        <Image src={file.preview} />
      </ThumbInner>
    </Thumb>
  ))

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview))
    },
    [files]
  )

  useEffect(() => {
    if (multiple) form.update(name, files)
    else form.update(name, files[0])
  }, [files])

  useEffect(() => {
    if (form.values[name]) form.blur(name)
  }, [form.values[name]])

  const formInputProps = useFormInput({
    name,
    ...form,
    // reakit overrides
    update: identity,
    blur: identity,
    validate: identity,
  })

  return (
    <Container>
      <InputContainer
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <Input {...getInputProps({ name, ...form, ...formInputProps })} />
        <InputLabel {...form} name={name} label={label} />
      </InputContainer>
      {!form.errors[name] && (
        <ThumbsContainer as="aside">{thumbs}</ThumbsContainer>
      )}
      <InputMessage {...form} name={name} />
    </Container>
  )
}

const Container = (props: FlexProps) => <Box p={3} {...props} />

const InputContainer = React.forwardRef((props: FlexProps, ref) => (
  <Flex
    ref={ref}
    {...props}
    flex={1}
    flexDirection="column"
    alignItems="center"
    p={4}
    css={{
      borderWidth: '2px',
      borderRadius: '2px',
      borderColor: getColor(props),
      borderStyle: 'dashed',
      backgroundColor: '#fafafa',
      color: '#bdbdbd',
      outline: 'none',
      transition: 'border 0.24s ease-in-out',
    }}
  />
))

const getColor = props => {
  if (props.isDragAccept) {
    return '#00e676'
  }
  if (props.isDragReject) {
    return '#ff1744'
  }
  if (props.isDragActive) {
    return '#2196f3'
  }
  return '#eeeeee'
}

const Input = React.forwardRef((props: TextProps, ref) => {
  return (
    <Text
      ref={ref}
      as="input"
      type="file"
      {...props}
      // reakit overrides
      value={undefined}
    />
  )
})

const InputLabel = (props: TextProps) => (
  <Text
    pr={2}
    pb={2}
    as={FormLabel}
    {...props}
    name={props.name}
    label={props.label}
  />
)

const InputMessage = (props: TextProps) => {
  return (
    <Text
      pr={2}
      pt={2}
      color="danger"
      as={FormMessage}
      {...props}
      name={props.name}
    />
  )
}

const ThumbsContainer = (props: FlexProps) => (
  <Flex {...props} flexDirection={'row'} flexWrap={'wrap'} mt={4} />
)

const Thumb = (props: FlexProps) => (
  <Flex
    {...props}
    display={'inline-flex'}
    p={2}
    mb={3}
    mr={3}
    css={{
      height: '16rem',
      width: '16rem',
      borderRadius: 2,
      border: '1px solid #eaeaea',
      boxSizing: 'border-box',
    }}
  />
)

const ThumbInner = (props: FlexProps) => (
  <Flex {...props} minWidth={0} css={{ overflow: 'hidden' }} />
)

const Image = (props: BoxProps) => (
  <Box
    as="img"
    {...props}
    display="block"
    height="100%"
    css={{ width: 'auto' }}
  />
)
