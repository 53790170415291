import React, { useEffect, useState } from 'react'
import { Flex, Box, Text } from 'rebass/styled-components'
import { useCheckboxState, Checkbox, CheckboxOptions } from 'reakit/Checkbox'
import { Section, InputField } from './MerchantForm'
import FileInputField from './FileInputField'

interface IsDirectorCheckboxProps extends CheckboxOptions {
  text: string
}

function IsDirectorCheckbox(props: IsDirectorCheckboxProps) {
  return (
    <Flex flexDirection="row" alignItems="center" pl={3} pr={4} pb={2} pt={2}>
      <Checkbox
        {...props}
        color="primary.main"
        minWidth="unset"
        css={{ transform: 'scale(1.5)' }}
      />
      <Text px={2} fontFamily="body">
        {props.text}
      </Text>
    </Flex>
  )
}

interface IsDirectorProps {
  setIsDirector: (boolean) => void
}

function IsDirector(props: IsDirectorProps) {
  const { setIsDirector } = props

  const isOwnerCheckbox = useCheckboxState({ state: false })
  const isBoardMemberCheckbox = useCheckboxState({ state: false })
  const isManagerCheckbox = useCheckboxState({ state: false })

  useEffect(() => {
    setIsDirector(
      isOwnerCheckbox.state ||
        isBoardMemberCheckbox.state ||
        isManagerCheckbox.state
    )
  }, [isOwnerCheckbox, isBoardMemberCheckbox, isManagerCheckbox])

  return (
    <Box p={3} py fontFamily="body">
      <Text py={2}>
        You must be a director, owner, or controlling individual to sign your
        company up with Choice. Tick all that apply to you:
      </Text>
      <IsDirectorCheckbox
        {...isOwnerCheckbox}
        text="Owner of more than 25% of the business"
      />
      <IsDirectorCheckbox
        {...isBoardMemberCheckbox}
        text="Member of the board of directors"
      />
      <IsDirectorCheckbox
        {...isManagerCheckbox}
        text="Have significant day to day responsibility in management (ie General Manager, CEO or similar)"
      />
    </Box>
  )
}

export default function DirectorDetails() {
  const [isDirector, setIsDirector] = useState(false)

  return (
    <Section title="Director Details">
      <IsDirector setIsDirector={setIsDirector} />
      <InputField
        disabled={!isDirector}
        name="directorLegalName"
        label="What's your legal name (as on your ID provided below)?"
        placeholder="Charlotte Doe"
      />
      <InputField
        disabled={!isDirector}
        name="directorNickName"
        label="What should we call you?"
        placeholder="Charlie"
      />
      <InputField
        disabled={!isDirector}
        as="textarea"
        name="directorAddress"
        label="What's your personal address?"
        placeholder="123 Seseme St"
      />
      <InputField
        disabled={!isDirector}
        type="date"
        name="directorDateOfBirth"
        label="What's your date of birth?"
        placeholder="01/01/1980"
      />
      <Text p={3} fontFamily="body">
        We are required by New Zealand law to ask for a picture of your driver’s
        license or passport. We keep all of your personal verification data
        safely secured. &nbsp;
        <Text as="a" href="/welcome-aboard" target="_blank" color="dark">
          Learn more
        </Text>
      </Text>
      <FileInputField
        name="directorPhotoIdentification"
        label="Please upload a picture of your driver's license or passport."
      />
      <Text p={3} fontFamily="body">
        We are required by New Zealand law to ask for a proof of address. This
        is so that the Choice platform stays safe for all New Zealanders. &nbsp;
        <Text as="a" href="/welcome-aboard" target="_blank" color="dark">
          Learn more
        </Text>
      </Text>
      <FileInputField
        name="directorProofOfAddress"
        label="Please upload a proof of address such as phone or utility bill, bank statement, or government letter"
      />
    </Section>
  )
}
