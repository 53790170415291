import React, { useMemo } from 'react'
import { Button, Box, Flex, Text } from 'rebass/styled-components'
import { Separator } from 'reakit/Separator'
import { ObjectSchema } from 'yup'
import ReCAPTCHA from 'react-google-recaptcha'
import { assign, values, keys, set } from 'lodash'

import {
  unstable_useFormState as useFormState,
  unstable_Form as BaseForm,
  unstable_FormLabel as FormLabel,
  unstable_FormInput as FormInput,
  unstable_FormMessage as FormMessage,
  unstable_FormSubmitButton as FormSubmitButton,
} from 'reakit/Form'
import { Container } from '../../base'

export interface FormProps<T extends object> {
  children: React.ReactNode
  initialValues: T
  schema: ObjectSchema<T>
  onSubmit: (T, setErrs: (errors) => void) => void
}

export const FormContext = React.createContext(null)

export function Form<T extends object>(
  props: FormProps<T>
): React.ReactElement {
  const { children, schema, initialValues, onSubmit } = props
  const [serverErrors, setServerErrors] = React.useState({})
  const submitAndSetServerErrors = React.useCallback(
    values => onSubmit(values, setServerErrors),
    [onSubmit]
  )
  const validateAndClearServerErrors = useMemo(() => {
    const emptyServerErrors = {}
    const validate = validateWithYup(schema)
    return values => {
      setServerErrors(emptyServerErrors)
      return validate(values)
    }
  }, [schema])
  const form = useFormState<T>({
    values: initialValues,
    validateOnChange: false,
    validateOnBlur: true,
    resetOnUnmount: false,
    onValidate: validateAndClearServerErrors,
    onSubmit: submitAndSetServerErrors,
  })

  const errors = React.useMemo(() => {
    return assign({}, form.errors, serverErrors)
  }, [form.errors, serverErrors])

  React.useEffect(() => {
    if (keys(errors).length > 0) {
      var selector = `[aria-invalid=true][id^=${form.baseId}]`
      var element = document.querySelector(selector)
      if (element != null) {
        element.parentElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        })
      }
    }
  }, [errors])

  const formEnhanced = assign({}, form, {
    errors,
    serverErrors,
  })

  const value = React.useMemo(() => formEnhanced, values(formEnhanced))

  return (
    <FormContext.Provider value={value}>
      <BaseForm {...formEnhanced}>{children}</BaseForm>
    </FormContext.Provider>
  )
}

function validateWithYup(yupSchema) {
  return values =>
    yupSchema.validate(values, { abortEarly: false }).then(
      () => {},
      error => {
        if (error.inner.length) {
          throw error.inner.reduce(
            (acc, curr) => set(acc, curr.path, curr.message),
            {}
          )
        }
      }
    )
}

export interface SubmitButtonProps {
  disabled?: boolean
  focusable?: boolean
}

export function SubmitButton(props) {
  const form = React.useContext(FormContext)
  const { disabled, ...submitButtonProps } = props
  const bgColor = disabled ? 'muted' : 'primary.dark'

  const baseServerError = form.serverErrors.base

  return (
    <>
      <Button
        {...form}
        as={FormSubmitButton}
        m={4}
        px={4}
        py={3}
        bg={bgColor}
        disabled={disabled}
        fontFamily="button"
      >
        Submit
      </Button>
      {baseServerError && (
        <Text mx={4} pr={2} pt={2} color="danger">
          {form.serverErrors.base}
        </Text>
      )}
    </>
  )
}

export interface SectionProps {
  children: React.ReactNode
  title: string
}

export function Section(props: SectionProps): React.ReactElement {
  const { children, title } = props

  return (
    <Flex as="section" flexDirection="column" mx={4} fontFamily="body">
      <Box
        as={Separator}
        orientation="horizontal"
        color="black"
        my={2}
        mx={0}
      />
      <Box as="header" my={2}>
        <Text as="h2" fontSize={5} fontFamily="heading">
          {title}
        </Text>
      </Box>
      {children}
    </Flex>
  )
}

export interface InputFieldProps {
  as: React.ReactType
  name: string
  label: string
  disabled?: boolean
  focusable?: boolean
  type?: string
  placeholder?: string
}

export function InputField(props: InputFieldProps): React.ReactElement {
  const { as, name, label, ...inputProps } = props
  const form = React.useContext(FormContext)

  return (
    <Container pl={3} pt={3} pb={3} fontFamily="body">
      <Text pr={2} pb={2} as={FormLabel} {...form} name={name} label={label} />
      <Text
        as={FormInput}
        forwardedAs={as}
        {...form}
        {...inputProps}
        name={name}
      />
      <Text
        pr={2}
        pt={2}
        color="danger"
        as={FormMessage}
        {...form}
        name={name}
      />
    </Container>
  )
}

export interface CaptchaFieldProps {
  name: string
  sitekey: string
}

export function CaptchaField(props: CaptchaFieldProps) {
  const { name, sitekey } = props

  const form = React.useContext(FormContext)

  const ref = React.useRef()

  React.useEffect(() => {
    if (form.serverErrors[name] && ref.current) {
      ref.current.reset()
    }
  }, [form])

  return (
    <Container p={3} fontFamily="body" alignItems="center">
      <ReCAPTCHA ref={ref} sitekey={sitekey} onChange={handleChange} />
      <Text
        pr={2}
        pt={2}
        color="danger"
        as={FormMessage}
        {...form}
        name={name}
      />
    </Container>
  )

  function handleChange(value) {
    form.update(name, value)
  }
}
