import React from 'react'
import { Text } from 'rebass/styled-components'
import { Section, InputField } from './MerchantForm'

export default function BankDetails() {
  return (
    <Section title="Bank Details">
      <InputField
        name="bankNumber"
        label="What's your business bank account number? (format: BB-bbbb-AAAAAAA-SSS)"
        placeholder="12-3456-789101-00"
      />
      <Text p={3} fontFamily="body">
        We will send all withdrawal requests and settlements to this New Zealand
        bank account.
      </Text>
      <InputField
        name="bankNumberConfirm"
        label="Please confirm your business bank account number. (format: BB-bbbb-AAAAAAA-SSS)"
        placeholder="12-3456-789101-00"
      />
    </Section>
  )
}
