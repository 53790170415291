import React, { useCallback, useMemo } from 'react'
import { navigate, PageRendererProps } from 'gatsby'
import { parse as parseQueryString } from 'query-string'

import { Container } from '../components/base'
import { withLayout } from '../components/Layout'
import MerchantSignUp from '../components/merchant/Sign-up/MerchantSignUp'

interface MerchantSignupProps extends PageRendererProps {}

const MerchantSignupPage = (props: MerchantSignupProps): React.ReactElement => {
  const { location } = props

  const params = useMemo(() => parseQueryString(location.search), [
    location.search
  ])
  const isSubmitted = useMemo(() => {
    if (params.submitted === 'true') return true
    else if (params.submitted === 'false') return false
    else return null
  }, [params.submitted])

  const navigateToSubmitted = useCallback(() => {
    navigate('/merchant-signup?submitted=true')
  }, [])

  return (
    <Container>
      <MerchantSignUp
        isSubmitted={isSubmitted}
        navigateToSubmitted={navigateToSubmitted}
      />
    </Container>
  )
}

export default withLayout(MerchantSignupPage)
